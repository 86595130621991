/**
 * @deprecated
 */
class HttpClient {
    static #instance: HttpClient | null = null;

    static getInstance(): HttpClient {
        if (this.#instance === null) {
            this.#instance = new HttpClient();
        }

        return this.#instance;
    }

    async get(url: string, options?: RequestInit): Promise<Response> {
        return this.#request(url, options);
    }

    async post(url: string, data?: BodyInit, options?: RequestInit): Promise<Response> {
        const defaultOptions: RequestInit = {
            method: 'post',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };

        const requestOptions: RequestInit = { ...defaultOptions, ...options };

        return this.#request(url, requestOptions);
    }

    async put(url: string, data?: BodyInit, options?: RequestInit): Promise<Response> {
        const defaultOptions: RequestInit = {
            method: 'put',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };

        const requestOptions: RequestInit = { ...defaultOptions, ...options };

        return this.#request(url, requestOptions);
    }

    async delete(url: string, data?: BodyInit, options?: RequestInit): Promise<Response> {
        const defaultOptions: RequestInit = {
            method: 'delete',
            body: data
        };

        const requestOptions: RequestInit = { ...defaultOptions, ...options };

        return this.#request(url, requestOptions);
    }

    convertFormDataToQueryString(data: FormData): string {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new URLSearchParams(data as any).toString();
    }

    async #request(url: string, options?: RequestInit): Promise<Response> {
        const defaultOptions: RequestInit = {
            method: 'get',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const requestOptions: RequestInit = { ...defaultOptions, ...options };

        return fetch(url, requestOptions)
            .then(this.#requestStatus.bind(this))
            .then(this.#responseType.bind(this));
    }

    async #requestStatus(response: Response): Promise<Response> {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response);
        }
        if (response.status >= 400 && response.status < 500) {
            const contentType: string | null = response.headers.get('content-type');

            if (contentType !== null && contentType.indexOf('application/json') !== -1) {
                // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                return response.json().then(async (json) => Promise.reject(json));
            }

            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            return response.text().then(async (text) => Promise.reject(text));
        }

        return Promise.reject(new Error(response.statusText));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-redundant-type-constituents
    async #responseType(response: Response): Promise<string | any> {
        const contentType: string | null = response.headers.get('content-type');

        if (contentType !== null && contentType.indexOf('application/json') !== -1) {
            return response.json();
        }

        return response.text();
    }
}

export default HttpClient.getInstance();
