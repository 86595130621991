import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import iconTemplate from '../../../../src/components/protons/icon/Icon.create';
import { IconPositions, type IconProps } from '../../../../src/components/protons/icon/Icon';
import type { DefaultComponentType } from '../../../../src/utils/types';
import './Anchor.pcss';

export enum AnchorVariant {
    inline = 'inline',
    ui = 'ui'
}

export type AnchorProps = DefaultComponentType & {
    label: string;
    href?: string;
    target?: string;
    rel?: string;
    icon?: IconProps;
    iconPosition?: IconPositions;
    variant?: AnchorVariant;
};

export default class Anchor extends HTMLAnchorElement {
    get variant(): string | undefined {
        const variant = this.getAttribute('variant');
        if (variant === null) {
            return undefined;
        }

        const variants = <string[]>Object.values(AnchorVariant);

        return variants.includes(variant) ? variant : undefined;
    }

    set variant(value: string | undefined) {
        if (!value) {
            this.removeAttribute('variant');
            return;
        }

        const variant = <string[]>Object.values(AnchorVariant);
        if (!variant.includes(value)) {
            return;
        }

        this.classList.remove(`nh-anchor--${this.variant}`);
        this.setAttribute('variant', value);
        this.classList.add(`nh-anchor--${value}`);
    }

    #label: string = this.getAttribute('label') || '';

    get label(): string {
        return this.#label;
    }

    set label(value: string) {
        this.#label = value;

        let labelElement: HTMLSpanElement | null = this.querySelector('.nh-anchor__label');

        if (!value) {
            labelElement?.remove();
            return;
        }

        if (!labelElement) {
            labelElement = Anchor.#createLabel(value);
            this.appendChild(labelElement);
        }

        labelElement.innerText = value;
    }

    #icon: IconProps | null = null;

    get icon(): IconProps | null {
        return this.#icon;
    }

    set icon(value: IconProps | null) {
        this.#icon = value;

        if (!this.#icon) {
            return;
        }

        let icon = this.querySelector('.nh-icon');

        if (!icon) {
            icon = parseStringAsHtml(iconTemplate(this.#icon), '.nh-icon') as HTMLElement;

            if (this.firstChild) {
                this.insertBefore(icon, this.firstChild);
                return;
            }

            this.appendChild(icon);
        }
    }

    get iconPosition(): string {
        const position = this.getAttribute('icon-position');
        if (position === null) {
            return IconPositions.LEFT;
        }

        const positions = <string[]>Object.values(IconPositions);
        return positions.includes(position) ? position : IconPositions.LEFT;
    }

    set iconPosition(value: string) {
        if (value === '') {
            this.removeAttribute('icon-position');
            return;
        }

        const positions = <string[]>Object.values(IconPositions);
        if (!positions.includes(value)) {
            this.removeAttribute('icon-position');
            return;
        }

        this.setAttribute('icon-position', value);
        this.classList.toggle('nh-anchor--icon-left', value === IconPositions.LEFT);
        this.classList.toggle('nh-anchor--icon-right', value === IconPositions.RIGHT);
    }

    constructor(props?: AnchorProps) {
        super();

        if (!props) {
            return;
        }

        if (props.target) {
            this.target = props.target;
        }

        if (props.rel) {
            this.rel = props.rel;
        }

        if (props.href) {
            this.href = props.href;
        }

        if (props.label) {
            this.label = props.label;
        }

        if (props.icon) {
            this.icon = props.icon;
        }
    }

    protected connectedCallback(): void {
        if (Anchor.#isExternalUrl(this.href)) {
            const target = this.target || '_blank';
            const rel = this.rel || 'noopener noreferrer';
            this.setAttribute('target', target);
            this.setAttribute('rel', rel);
        }
    }

    static #createLabel(label: string): HTMLSpanElement {
        const labelElement = document.createElement('span');
        labelElement.classList.add('nh-anchor__label');
        labelElement.innerText = label;
        return labelElement;
    }

    static #isExternalUrl(href: string): boolean {
        if (!href) {
            return false;
        }

        const absoluteHref = this.#getAbsolutePath(href);
        const currentDomain = window.location.hostname;
        const url = new URL(absoluteHref);
        const domain = url.hostname;

        return domain !== currentDomain;
    }

    static #getAbsolutePath(href: string): string {
        const link = document.createElement('a');
        link.href = href;
        return link.href;
    }
}

if (!window.customElements.get('nh-anchor')) {
    window.customElements.define('nh-anchor', Anchor, { extends: 'a' });
}
